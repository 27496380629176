<template>
  <v-container class="bg-color-theme px-3" v-if="!pageLoading">
    <validation-observer ref="observer">
      <v-form
        ref="form"
        @submit.prevent="submit"
        lazy-validation
        autocomplete="off"
      >
        <v-row>
          <v-col cols="12" md="12">
            <v-card elevation="2" class="py-9 px-5">
              <h6 class="text-center font-14px text-333 font-weight-medium">
                {{ shopName }}
              </h6>
              <v-card-subtitle class="font-12px text-333 px-0 line-height-16"
                >追加したいユーザーのメールアドレスを入力し、追加するボタンを押すと、該当ユーザーに招待メールが送信されます。
              </v-card-subtitle>

              <v-row class="py-1 mt-4 mb-4">
                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required:メールアドレス|email"
                    name="email"
                  >
                    <v-label>メールアドレス </v-label>
                    <v-text-field
                      class="font-14px"
                      placeholder="mail@redish.com"
                      solo
                      background-color="#EAEDF0"
                      v-model="inviteMail"
                      :error-messages="errors"
                      autocomplete="chrome-off"
                      dense
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="py-0">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required:アクセス権限"
                    name="userRole"
                  >
                    <v-label>アクセス権限 </v-label>
                    <div class="role-cls" v-if="$store.getters.getShopAuthUser[0].user_role != 'M'">パートユーザー </div>
                    <v-select v-else
                      :label="userLabel"
                      solo
                      dense
                      :items="items"
                      item-text="text"
                      item-value="id"
                      class="font-14px"
                      v-model="userRole"
                      :error-messages="errors"
                      background-color="#EAEDF0"
                    ></v-select>
                  </validation-provider>
                  <v-card-subtitle
                    class="font-12px text-85 px-0 py-0 line-height-16"
                    >すべての情報を閲覧・編集できるマスターユーザーか日報、現金出納、買掛の登録や編集、他一部機能が利用可能なパートユーザーかを選択してください。
                  </v-card-subtitle>
                </v-col>
              </v-row>

              <v-btn
                class="mt-3 font-weight-bold"
                block
                color="primary"
                type="submit"
                :loading="loading"
              >
                追加する
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
export default {
  name: "InviteUser",
  data() {
    return {
      shopName: "",
      inviteMail: null,
      userRole: "M",
      userLabel: "マスターユーザー",
      items:[{ id: 'M', text: 'マスターユーザー' }, { id: 'P', text: 'パートユーザー' }],
      loading: false,
      pageLoading: false,
    };
  },
  props: ["shop"],
  created() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      this.pageLoading = true;
      this.loading = true;
      await this.$store
        .dispatch("SHOP_GET", { id: this.shop })
        .then(response => {
          let shop = response.data.data.shop;
          this.shopName = shop.store_name
            ? shop.store_name
            : shop.store_display_name;

            let authUserData = {
              shop_id: this.shop, //Shop Id
              user_id: this.$store.getters.user.id
            };

            this.$store.dispatch("SHOP_USER_AUTH", authUserData).finally(() => {
              if(this.$store.getters.getShopAuthUser[0].user_role != "M"){
                this.userRole = 'P';
              }
              this.loading = false;
              this.pageLoading = false;
            });

        });
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        this.loading = true;
        this.$store
          .dispatch("SHOP_INVITE_USER", {
            shop_id: this.shop,
            user_role: this.userRole,
            email: this.inviteMail
          })
          .then(response => {
            this.$router.push({
              name: "StoreDetails",
              params: { id: this.shop }
            });
            this.$store.commit("showAlert", {
              text: "ユーザーを追加しました。追加メンバーにメールを送信しました。",
              successStatus: "info"
            });

            let shop = response.data.data.shop;
            this.shopName = shop.store_name
              ? shop.store_name
              : shop.store_display_name;
          })
          .catch(error => {
            if (error?.data?.data?.message) {
              this.$store.dispatch("SHOW_ALERT", {
                text: error?.data?.data?.message,
                successStatus: "info"
              });
            }
          }).finally(() => (this.loading = false));
      });
    }
  }
};
</script>
